import { default as aboutBgr4kSO3OcMeta } from "/home/jerry/workdir/vrpingpong.net/pages/about.vue?macro=true";
import { default as confirmtc9O72PyoGMeta } from "/home/jerry/workdir/vrpingpong.net/pages/confirm.vue?macro=true";
import { default as _91id_93JwPxThQLOgMeta } from "/home/jerry/workdir/vrpingpong.net/pages/files/[id].vue?macro=true";
import { default as indexxKgqnsK3pxMeta } from "/home/jerry/workdir/vrpingpong.net/pages/files/index.vue?macro=true";
import { default as indexccWCUca601Meta } from "/home/jerry/workdir/vrpingpong.net/pages/index.vue?macro=true";
import { default as loginmCVmBZgfc3Meta } from "/home/jerry/workdir/vrpingpong.net/pages/login.vue?macro=true";
import { default as _91id_93YWs3V0wsi9Meta } from "/home/jerry/workdir/vrpingpong.net/pages/matches/[id].vue?macro=true";
import { default as indexHJfrHDANqAMeta } from "/home/jerry/workdir/vrpingpong.net/pages/matches/index.vue?macro=true";
import { default as _91id_93bD5gDAZ4xMMeta } from "/home/jerry/workdir/vrpingpong.net/pages/products/[id].vue?macro=true";
import { default as indexun0MzWAjkjMeta } from "/home/jerry/workdir/vrpingpong.net/pages/products/index.vue?macro=true";
import { default as profilegnbKqwv3eOMeta } from "/home/jerry/workdir/vrpingpong.net/pages/profile.vue?macro=true";
import { default as registerH0y9ZjTVp6Meta } from "/home/jerry/workdir/vrpingpong.net/pages/register.vue?macro=true";
import { default as _91id_935SmFoLJxUtMeta } from "/home/jerry/workdir/vrpingpong.net/pages/sandbox/[id].vue?macro=true";
import { default as indexBpfjBXwjx0Meta } from "/home/jerry/workdir/vrpingpong.net/pages/sandbox/index.vue?macro=true";
import { default as signupM8bsdCqRFtMeta } from "/home/jerry/workdir/vrpingpong.net/pages/signup.vue?macro=true";
import { default as uploadtiNgEq4TLrMeta } from "/home/jerry/workdir/vrpingpong.net/pages/upload.vue?macro=true";
import { default as indexD5GwhxJNNqMeta } from "/home/jerry/workdir/vrpingpong.net/pages/users/index.vue?macro=true";
export default [
  {
    name: aboutBgr4kSO3OcMeta?.name ?? "about",
    path: aboutBgr4kSO3OcMeta?.path ?? "/about",
    meta: aboutBgr4kSO3OcMeta || {},
    alias: aboutBgr4kSO3OcMeta?.alias || [],
    redirect: aboutBgr4kSO3OcMeta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/about.vue").then(m => m.default || m)
  },
  {
    name: confirmtc9O72PyoGMeta?.name ?? "confirm",
    path: confirmtc9O72PyoGMeta?.path ?? "/confirm",
    meta: confirmtc9O72PyoGMeta || {},
    alias: confirmtc9O72PyoGMeta?.alias || [],
    redirect: confirmtc9O72PyoGMeta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JwPxThQLOgMeta?.name ?? "files-id",
    path: _91id_93JwPxThQLOgMeta?.path ?? "/files/:id()",
    meta: _91id_93JwPxThQLOgMeta || {},
    alias: _91id_93JwPxThQLOgMeta?.alias || [],
    redirect: _91id_93JwPxThQLOgMeta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/files/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxKgqnsK3pxMeta?.name ?? "files",
    path: indexxKgqnsK3pxMeta?.path ?? "/files",
    meta: indexxKgqnsK3pxMeta || {},
    alias: indexxKgqnsK3pxMeta?.alias || [],
    redirect: indexxKgqnsK3pxMeta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/files/index.vue").then(m => m.default || m)
  },
  {
    name: indexccWCUca601Meta?.name ?? "index",
    path: indexccWCUca601Meta?.path ?? "/",
    meta: indexccWCUca601Meta || {},
    alias: indexccWCUca601Meta?.alias || [],
    redirect: indexccWCUca601Meta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginmCVmBZgfc3Meta?.name ?? "login",
    path: loginmCVmBZgfc3Meta?.path ?? "/login",
    meta: loginmCVmBZgfc3Meta || {},
    alias: loginmCVmBZgfc3Meta?.alias || [],
    redirect: loginmCVmBZgfc3Meta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93YWs3V0wsi9Meta?.name ?? "matches-id",
    path: _91id_93YWs3V0wsi9Meta?.path ?? "/matches/:id()",
    meta: _91id_93YWs3V0wsi9Meta || {},
    alias: _91id_93YWs3V0wsi9Meta?.alias || [],
    redirect: _91id_93YWs3V0wsi9Meta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/matches/[id].vue").then(m => m.default || m)
  },
  {
    name: indexHJfrHDANqAMeta?.name ?? "matches",
    path: indexHJfrHDANqAMeta?.path ?? "/matches",
    meta: indexHJfrHDANqAMeta || {},
    alias: indexHJfrHDANqAMeta?.alias || [],
    redirect: indexHJfrHDANqAMeta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/matches/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bD5gDAZ4xMMeta?.name ?? "products-id",
    path: _91id_93bD5gDAZ4xMMeta?.path ?? "/products/:id()",
    meta: _91id_93bD5gDAZ4xMMeta || {},
    alias: _91id_93bD5gDAZ4xMMeta?.alias || [],
    redirect: _91id_93bD5gDAZ4xMMeta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: indexun0MzWAjkjMeta?.name ?? "products",
    path: indexun0MzWAjkjMeta?.path ?? "/products",
    meta: indexun0MzWAjkjMeta || {},
    alias: indexun0MzWAjkjMeta?.alias || [],
    redirect: indexun0MzWAjkjMeta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: profilegnbKqwv3eOMeta?.name ?? "profile",
    path: profilegnbKqwv3eOMeta?.path ?? "/profile",
    meta: profilegnbKqwv3eOMeta || {},
    alias: profilegnbKqwv3eOMeta?.alias || [],
    redirect: profilegnbKqwv3eOMeta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: registerH0y9ZjTVp6Meta?.name ?? "register",
    path: registerH0y9ZjTVp6Meta?.path ?? "/register",
    meta: registerH0y9ZjTVp6Meta || {},
    alias: registerH0y9ZjTVp6Meta?.alias || [],
    redirect: registerH0y9ZjTVp6Meta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/register.vue").then(m => m.default || m)
  },
  {
    name: _91id_935SmFoLJxUtMeta?.name ?? "sandbox-id",
    path: _91id_935SmFoLJxUtMeta?.path ?? "/sandbox/:id()",
    meta: _91id_935SmFoLJxUtMeta || {},
    alias: _91id_935SmFoLJxUtMeta?.alias || [],
    redirect: _91id_935SmFoLJxUtMeta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/sandbox/[id].vue").then(m => m.default || m)
  },
  {
    name: indexBpfjBXwjx0Meta?.name ?? "sandbox",
    path: indexBpfjBXwjx0Meta?.path ?? "/sandbox",
    meta: indexBpfjBXwjx0Meta || {},
    alias: indexBpfjBXwjx0Meta?.alias || [],
    redirect: indexBpfjBXwjx0Meta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/sandbox/index.vue").then(m => m.default || m)
  },
  {
    name: signupM8bsdCqRFtMeta?.name ?? "signup",
    path: signupM8bsdCqRFtMeta?.path ?? "/signup",
    meta: signupM8bsdCqRFtMeta || {},
    alias: signupM8bsdCqRFtMeta?.alias || [],
    redirect: signupM8bsdCqRFtMeta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: uploadtiNgEq4TLrMeta?.name ?? "upload",
    path: uploadtiNgEq4TLrMeta?.path ?? "/upload",
    meta: uploadtiNgEq4TLrMeta || {},
    alias: uploadtiNgEq4TLrMeta?.alias || [],
    redirect: uploadtiNgEq4TLrMeta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/upload.vue").then(m => m.default || m)
  },
  {
    name: indexD5GwhxJNNqMeta?.name ?? "users",
    path: indexD5GwhxJNNqMeta?.path ?? "/users",
    meta: indexD5GwhxJNNqMeta || {},
    alias: indexD5GwhxJNNqMeta?.alias || [],
    redirect: indexD5GwhxJNNqMeta?.redirect,
    component: () => import("/home/jerry/workdir/vrpingpong.net/pages/users/index.vue").then(m => m.default || m)
  }
]