import revive_payload_client_4sVQNw7RlN from "/home/jerry/workdir/vrpingpong.net/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/jerry/workdir/vrpingpong.net/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/jerry/workdir/vrpingpong.net/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_I4Hv4qpJHd from "/home/jerry/workdir/vrpingpong.net/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_yVLowv6hDl from "/home/jerry/workdir/vrpingpong.net/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/jerry/workdir/vrpingpong.net/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/jerry/workdir/vrpingpong.net/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/jerry/workdir/vrpingpong.net/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_LDumGYo2KH from "/home/jerry/workdir/vrpingpong.net/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/home/jerry/workdir/vrpingpong.net/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/home/jerry/workdir/vrpingpong.net/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/home/jerry/workdir/vrpingpong.net/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/jerry/workdir/vrpingpong.net/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_I4Hv4qpJHd,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes
]